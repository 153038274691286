@import './vars';

form{
  font-family: 'FreightSansProBook';

  legend{
    margin-bottom: 40px;
    flex: 1 0 100%;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 23px;
    font-family: 'FreightSansProSemibold';
    letter-spacing: -1px;
    @media( min-width: 836px ){
      // font-size: 30px;
    }
  }

  input[type="checkbox"]:disabled+label{
    opacity: 0.2;
    cursor: not-allowed;
  }

  fieldset{
    display: flex;
    flex-direction: column;
    font-family: inherit;
    &.bordered{
      border-top: 2px solid $grey1;
      padding-top: 10px;
    }

    label{
      font-size: 15px;
      margin-bottom: 0;
      @media( min-width: 836px ){
        font-size: 15px;
      }
    }

    .input-group{
      display: flex;
      align-items: center;
    }

    input.input-text{
      font-family: 'FreightSansProBook';
      border: 1px solid #898989;
      border-radius: 4px;
      height: 42px;
      padding: 0 15px;
      font-size: 15px;
      @media( min-width: 836px ){
        font-size: 15px;
      }
      &:focus{
        border-color: $orange;
        box-shadow: 0px 0px 5px 0px rgba(243,196,4,1);
      }
    }
    textarea{
      border: 1px solid $grey1;
      border-radius: 4px;
      padding: 15px 15px;
      font-size: 15px;
      font-family: 'FreightSansProBook';
      height: 163px;
      width: 100%;
      @media( min-width: 836px ){
      }
      &:focus{
        border-color: $orange;
        box-shadow: 0px 0px 5px 0px rgba(243,196,4,1);
      }
    }

    p{
      margin: 0;
      font-family: 'FreightSansProBook';
      font-size: 19px;
      @media( min-width: 836px ){
      }
    }

  }
}


