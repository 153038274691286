.button-accordion{
  display: flex;
  align-items: center;
  span{
    margin-right: 15px;
  }
  svg{
    transform: rotate( -90deg );
    width: 25px;
    height: 25px;
  }
  &.active{
    svg{
      transform: rotate( 0deg );
    }
  }
}
