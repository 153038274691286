.triangle {
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 50px 50px 50px;
  border-color: transparent transparent theme( 'colors.yellow') transparent;

  position: absolute;
  z-index: 1;
  top: calc( 100% - 1px );

  left: 50%;
  transform: translateX( -50% ) rotate(180deg);
  @media ( min-width: 1200px ){
    left: 50px;
    transform: translateX( 0% ) rotate(180deg);
  }
}
