.bg,
.bg-image{
  position: absolute;
  right: 0;
  bottom: 0;

  // object-fit: cover;
  // object-position: left bottom;

  background-repeat: no-repeat;
  background-position: right bottom;

  width: 100%;
  height: 50%;
  background-size: contain;

  @media( min-width: 1200px){
    width: 50%;
    height: 90%;
  }
}
