@media(max-width: @screen-l-from) {
    body {
        .burger {
            display: block;
            position: absolute;
            right: 0;
            margin-right: 127px !important;
            top: 45%;
            transform: translate(0, -50%);
            font-size: 30px;

            a {
                padding: 0px !important;
                i {
                    color: @color-black;
                    font-size: 24px;
                }
            }
        }
    }
}

@media(min-width: @screen-l-from) {
    body {
        background-color: @color-gray-1;

        aside.main-sidebar, 
        aside.main-sidebar::before,
        .burger {
            display: none;
        }
    
        &.sidebar-mini.sidebar-collapse .content-wrapper, 
        &.sidebar-mini.sidebar-collapse .main-footer, 
        &.sidebar-mini.sidebar-collapse .main-header {
            margin: 0 auto !important;
            max-width: 1340px;
        }

        .wrapper {
            .content-wrapper, 
            .main-footer, 
            .main-header {
                margin: 0 auto !important;
                max-width: 1340px;
            }
        }


        app-header {
            .main-header {
                //background-color: @accent-color-white;
                border: none;
            }
        }
        
    }
}

.border-bottom-100 {
    border-bottom: 1px solid @border-color;
    //background-color: @accent-color-white;
}

.content-wrapper {
    background-color: transparent;
}




//////TODO Refactor
body {
    .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
        background-color: rgb(255, 223, 15, 50%);
        border-color: rgb(255, 223, 15);
    }
    
    .custom-control-input:checked~.custom-control-label::before {
        color: #000;
    }
}


