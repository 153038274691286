a.link-underlined-white{
  @apply
  underline decoration-white underline-offset-[4px] font-[700]
  ;
}

a.link-underlined-yellow{
  @apply
  underline decoration-orange underline-offset-[4px] font-[700]
  hover:decoration-orange
  ;
}
