

.ql-toolbar {
    font-family: Poppins, Helvetica, "sans-serif"; }
    .ql-toolbar.ql-snow {
      border: 1px solid #EBEDF3;
      border-top-left-radius: 0.42rem;
      border-top-right-radius: 0.42rem; }
      .ql-toolbar.ql-snow .ql-picker .ql-fill,
      .ql-toolbar.ql-snow .ql-picker .ql-stroke,
      .ql-toolbar.ql-snow button .ql-fill,
      .ql-toolbar.ql-snow button .ql-stroke {
        stroke: #B5B5C3; }
      .ql-toolbar.ql-snow .ql-picker .ql-fill,
      .ql-toolbar.ql-snow button .ql-fill {
        fill: #B5B5C3; }
      .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-fill,
      .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-stroke, .ql-toolbar.ql-snow .ql-picker.ql-active .ql-fill,
      .ql-toolbar.ql-snow .ql-picker.ql-active .ql-stroke, .ql-toolbar.ql-snow .ql-picker:focus .ql-fill,
      .ql-toolbar.ql-snow .ql-picker:focus .ql-stroke, .ql-toolbar.ql-snow .ql-picker:hover .ql-fill,
      .ql-toolbar.ql-snow .ql-picker:hover .ql-stroke,
      .ql-toolbar.ql-snow button.ql-expanded .ql-fill,
      .ql-toolbar.ql-snow button.ql-expanded .ql-stroke,
      .ql-toolbar.ql-snow button.ql-active .ql-fill,
      .ql-toolbar.ql-snow button.ql-active .ql-stroke,
      .ql-toolbar.ql-snow button:focus .ql-fill,
      .ql-toolbar.ql-snow button:focus .ql-stroke,
      .ql-toolbar.ql-snow button:hover .ql-fill,
      .ql-toolbar.ql-snow button:hover .ql-stroke {
        stroke: #3699FF; }
      .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-fill, .ql-toolbar.ql-snow .ql-picker.ql-active .ql-fill, .ql-toolbar.ql-snow .ql-picker:focus .ql-fill, .ql-toolbar.ql-snow .ql-picker:hover .ql-fill,
      .ql-toolbar.ql-snow button.ql-expanded .ql-fill,
      .ql-toolbar.ql-snow button.ql-active .ql-fill,
      .ql-toolbar.ql-snow button:focus .ql-fill,
      .ql-toolbar.ql-snow button:hover .ql-fill {
        fill: #3699FF; }

  .ql-editor {
    color: #3F4254; }
    .ql-editor.ql-blank:before {
      left: auto !important;
      right: auto !important;
      color: #B5B5C3 !important;
      font-style: normal !important; }

  .ql-container.ql-snow {
    border: 1px solid #EBEDF3;
    border-top: 0;
    border-bottom-right-radius: 0.42rem;
    border-bottom-left-radius: 0.42rem; }

  .ql-snow .ql-picker .ql-picker-label {
    color: #B5B5C3; }
    .ql-snow .ql-picker .ql-picker-label.ql-active, .ql-snow .ql-picker .ql-picker-label:hover {
      color: #3699FF; }

  .ql-snow .ql-picker.ql-expanded {
    outline: none !important;
    border-color: transparent !important; }
    .ql-snow .ql-picker.ql-expanded .ql-picker-label {
      border-color: transparent !important;
      color: #3699FF;
      outline: none !important; }
      .ql-snow .ql-picker.ql-expanded .ql-picker-label.ql-active, .ql-snow .ql-picker.ql-expanded .ql-picker-label:hover {
        color: #3699FF; }
    .ql-snow .ql-picker.ql-expanded .ql-picker-options {
      border: 0;
      padding: 0.5rem 1rem;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      border-radius: 0.42rem; }
      .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
        color: #7E8299;
        outline: none; }
        .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item.ql-selected, .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item.ql-active, .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
          color: #3699FF; }

  .ql-snow .ql-tooltip {
    border: 0;
    padding: 0.5rem 1rem;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    border-radius: 0.42rem; }
    .ql-snow .ql-tooltip input[type=text] {
      border: 0;
      background-color: transparent;
      outline: none !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: 1px solid #EBEDF3;
      color: #3F4254;
      outline: none !important;
      border-radius: 0.42rem; }
      .ql-snow .ql-tooltip input[type=text]:active, .ql-snow .ql-tooltip input[type=text]:focus {
        border-color: #69b3ff !important; }
    .ql-snow .ql-tooltip .ql-preview {
      color: #7E8299; }
    .ql-snow .ql-tooltip .ql-action {
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      color: #7E8299; }
      .ql-snow .ql-tooltip .ql-action:hover {
        -webkit-transition: color 0.3s ease;
        transition: color 0.3s ease;
        color: #3699FF; }
    .modal .ql-snow .ql-tooltip.ql-editing {
      left: 20px !important; }

  .ql-snow .ql-editor pre.ql-syntax {
    background-color: #181C32;
    color: #B5B5C3;
    overflow: visible;
    border-radius: 0.42rem; }

  .ql-quil.ql-quil-plain .ql-toolbar {
    padding: 0;
    margin: 0;
    border: 0; }
    .ql-quil.ql-quil-plain .ql-toolbar:after {
      display: none; }
    .ql-quil.ql-quil-plain .ql-toolbar .ql-picker-label {
      padding-left: 0; }

  .ql-quil.ql-quil-plain .ql-container {
    border: 0; }

  .ql-quil.ql-quil-plain .ql-editor {
    border: 0;
    padding: 0; }


.table-no-header .ui-table .ui-table-thead>tr>th {
    background-color: #fff;
    height:1px;
    line-height: 1px;
    padding: 0 !important;
}

.new-table .ui-table .ui-table-tbody >tr:nth-child(even),
.table-no-header .ui-table .ui-table-tbody >tr:nth-child(even) {
        background-color: #f9fcff;
}

.new-table .ui-table .ui-table-tbody>tr td a.btn.btn-light:hover:not(.btn-text):not(:disabled):not(.disabled) i,
.table-no-header .ui-table .ui-table-tbody>tr td a.btn.btn-light:hover:not(.btn-text):not(:disabled):not(.disabled) i {
    color: #fd0;
}

.new-table .ui-table .ui-table-tbody>tr td a i,
.table-no-header .ui-table .ui-table-tbody>tr td a i {
    color: #fd0;
}

body .ui-table .ui-sortable-column.ui-state-highlight {
    background-image: linear-gradient(to bottom, #ffeb66 0, #fd0 100%);
    box-shadow: 0 1px 3px 0 rgb(0 0 0%);
    font-size: 14px !important;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 6px 20px !Important;
    border: 1px solid #fd0;
    color: #000;
}

.pi-sort-amount-up-alt:before,
.pi-sort-amount-down:before {
    color: #000;
}

body .ui-table .ui-sortable-column:focus,
body .ui-paginator .ui-paginator-pages .ui-paginator-page:focus,
body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
    box-shadow: inset 0 0 0 0.2em #ffe42e;
}

body .ui-dropdown:not(.ui-state-disabled).ui-state-focus,
.form-control:focus {
    border-color: #fd0;
    box-shadow: none;
}

body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
    background-image: linear-gradient(to bottom, #ffeb66 0, #fd0 100%);
    box-shadow: 0 1px 3px 0 rgb(0 0 0%);
    border: 1px solid #fd0;
    color: #000;
}

.row-action-click .primeng-datatable-container .ui-table-tbody tr:hover, .transactions .primeng-datatable-container .ui-table-tbody tr:hover {
    cursor: pointer;
    background-color: rgb(255 224 21 / 0.15);
}

tab.tab-pane {
  padding-top: 10px;
}

body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider,
body .ui-inputswitch.ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-image: linear-gradient(to bottom, #ffeb66 0, #fd0 100%);
  background-color: #fd0;
}

.more-details-controls {
  cursor: pointer;
}

body.login-page {
  margin: 0;
  padding: 0;
  background: url('./assets/img/login/login-hero-6.jpg') no-repeat;
  height: 100vh;
  font-family: sans-serif;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden
}

@media screen and (max-width: 600px) {
  body.login-page {
      background-size: fixed;
  }
}

body .ui-inputswitch.ui-inputswitch-checked.ui-inputswitch-focus .ui-inputswitch-slider {
  background-image: linear-gradient(to bottom, #ffeb66 0, #fd0 100%);
  background-color: #fd0;
  box-shadow: none !important;
}

body .ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
  box-shadow: none !important;
}




/* LOCALLOGIC */

body {
  font-size: 11px;
  font-family: sans-serif;
}
#llMap {
  height: 600px;
  float: left;
  margin-left: 20px;
  width: 600px;
  border-radius: 5px;
  border: 1px solid #DDD;
}
#llControls {
  float: left;
  width: 200px;
  min-height: 600px;
}
.filters-on-off, .filters {
  border: none;
  padding: 0;
  margin: 0;
}
.filters-on-off {
  height: 1em;
  display: block;
  line-height: 1em;
  font-size: 12px;
  margin-bottom: 1em;
}
.filters {
  margin-bottom: 2em;
}
.filters-on-off label,
.filters label {
  margin-right: 1em;
}
.filters label svg,
.filters label input {
  float: left;
  width: 1.4em;
  height: 1.4em;
  margin: 0 0.5em 0 0;
}
.filters label {
  font-size: 1em;
  display: block;
  overflow: hidden;
  margin: 0.5em 0;
  line-height: 1.4em;
  vertical-align: middle;
}
.filters label:hover,
.filters label.filter-selected {
  font-weight: bold;
}
.filters .category-title {
  display: block;
  font-size: 0.9em;
}


#local-profile-gmap
{
    width: 100%;
    height: 550px;
}


.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.main-header.navbar.navbar-expand {
  display: flex;
  justify-content: space-between;
}
