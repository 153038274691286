// @font-face {
//   font-family: 'FreightSansProSemibold';
//   src: url('./FreightSansPro/FreightSansProSemibold-Regular.eot');
//   src: local('FreightSans Pro Semibold'), local('FreightSansProSemibold-Regular'),
//       url('./FreightSansPro/FreightSansProSemibold-Regular.eot?#iefix') format('embedded-opentype'),
//       url('./FreightSansPro/FreightSansProSemibold-Regular.woff2') format('woff2'),
//       url('./FreightSansPro/FreightSansProSemibold-Regular.woff') format('woff'),
//       url('./FreightSansPro/FreightSansProSemibold-Regular.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'FreightSansProBook';
//   src: url('./FreightSansPro/FreightSansProBook-Regular.eot');
//   src: local('FreightSans Pro Book'), local('FreightSansProBook-Regular'),
//       url('./FreightSansPro/FreightSansProBook-Regular.eot?#iefix') format('embedded-opentype'),
//       url('./FreightSansPro/FreightSansProBook-Regular.woff2') format('woff2'),
//       url('./FreightSansPro/FreightSansProBook-Regular.woff') format('woff'),
//       url('./FreightSansPro/FreightSansProBook-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'FreightSansProBlack';
//   src: url('./FreightSansPro/FreightSansProBlack-Regular.eot');
//   src: local('FreightSans Pro Black'), local('FreightSansProBlack-Regular'),
//       url('./FreightSansPro/FreightSansProBlack-Regular.eot?#iefix') format('embedded-opentype'),
//       url('./FreightSansPro/FreightSansProBlack-Regular.woff2') format('woff2'),
//       url('./FreightSansPro/FreightSansProBlack-Regular.woff') format('woff'),
//       url('./FreightSansPro/FreightSansProBlack-Regular.ttf') format('truetype');
//   font-weight: 900;
//   font-style: normal;
// }

body{
	font-size: 30px;
	font-family: 'FreightSansProBook', sans-serif;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'FreightSansProBlack', sans-serif;
  letter-spacing: -3.32px;
}

h1{
	font-size: 60px;
}
h2{
}
h3 {
  font-family: "FreightSansProBook", sans-serif;
  letter-spacing: normal;
  font-size: 30px;
  font-weight: bold;
}

// desktop
@media( min-width: 992px){
	body{
		font-size: 20px;
	}
	h1{
		font-size: 101px;
	}
	h2{
	}
	h3{
	}
	.section-title{
	}
}
