section{
  overflow: hidden;
  background-color: #fff;
}

.heading{
  @apply uppercase
  text-[50px] 500:text-[58px] 836:text-[94px]
  leading-[0.7] tracking-[0px]
  opacity-[0.9]
  text-yellow

  mb-[10px] 1200:mb-[30px]
  ;

  &.heading-big{
    @apply
    text-[57px] 500:text-[67px] 836:text-[100px] 1200:text-[118px]
    leading-[0.7]
    ;
    // font-feature-settings: 'pnum' on, 'lnum' on;
  }

  // &.heading-light{
  //   @apply text-yellow;
  // }
  &.heading-fixed-for-mobile{
    @apply
    text-[40px] 500:text-[67px] 836:text-[100px] 1200:text-[118px]
    ;
  }
  &.heading-dark{
    @apply text-orange;
  }
}

.subheading{
  @apply
  text-[30px] tablet:text-[38px]
  leading-[1] xl:leading-[0.75]
  tablet:pl-[95px]

  mb-[10px] 1200:mb-[30px]
  ;
  // @apply lg:pl-[30px];

  // 1200+
  // @apply xl:text-[45px]

  font-family: 'FreightSansProBook', sans-serif;

  b{
    @apply leading-[0.75];
    font-family: 'FreightSansProSemibold';
  }
}

.subheading2{
  @apply
  text-[49px] tablet:text-[49px]
  leading-[1.3] xl:leading-[1.3]
  
  
  mb-[10px] 1200:mb-[30px]
  ;
  // @apply lg:pl-[30px];

  // 1200+
  // @apply xl:text-[45px]

  font-family: 'Futura PT';
  letter-spacing: 3px;

  b{
    @apply leading-[0.75];
    font-family: 'Futura PT';
  }

  .yellow-highlight {
    background-color: #FFDD00;
    padding: 0px 15px;
  }

  
  // @media( max-width: 836px){
  //   letter-spacing: 4px;
  //   font-size: 33px;
  // }
}

.quote{
  padding-left: 15px;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 2px;
    height: 100%;
    z-index: 0;
    background-color: #fff;
  }
  &.quote-yellow{
    &:before{
      background-color: #ffdc2e;
    }
  }
}
