@import './vars';

.custom-checkbox{
  display: none;
}
.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}
.custom-checkbox+label::before {
  content: '✔';
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border: 2px solid $black;
  border-radius: 3px;
  margin-right: 8px;
  @media( min-width: 600px ){
    margin-right: 12px;
  }
}
.custom-checkbox:checked+label::before {
  color: $black;
  background-color: $yellow;
  border-color: $yellow;
}
.custom-checkbox:checked+label{
}

