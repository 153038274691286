body 
{
    .swal2-container .swal2-styled.swal2-confirm,
    .show>.btn-primary,
    .btn-primary {
        border-radius: 18px;
        box-shadow: -0.9px 0.5px 3px 0 rgba(0, 0, 0, 0.15);
        background-image: linear-gradient( #FFE867, #FFDF08);
        border: none;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.42px;
        transition: 0.3s box-shadow;
        font-family: 'FreightSansProSemibold', sans-serif;
        color: #000;

        margin-bottom: 5px;
        padding: 15px 50px;
        @media ( min-width: 768px ){
            padding: 15px 70px;
        }
        @media ( min-width: 1200px ){
            padding: 10px 40px;
        }
        

        &.active,
        &:focus, 
        &:hover, 
        &:not(:disabled):not(.disabled).active, 
        &:not(:disabled):not(.disabled):active,
        &.dropdown-toggle {
            box-shadow: -0.9px 0.5px 3px 0 rgba(0, 0, 0, 0.5);
        }

        &:disabled {
            color: #000;
            background-image: linear-gradient(to bottom, #ffeb66 0, #fd0 100%);
            background-color: #fd0;
            border-color:#fd0;
            opacity: 1;
        }

        i {
            margin-right: 4px;
        }
    }

    
    .show>.btn-secondary,
    .btn-secondary,
    .swal2-styled.swal2-cancel {
        color: @accent-color-blue;
        background-color: @accent-color-white;
        text-transform: uppercase;
        letter-spacing: 0.42px;
        transition: 0.3s box-shadow;
        font-size: 14px;
        font-family: 'FreightSansProSemibold', sans-serif;
        margin-bottom: 5px;
        border-radius: 18px;

        &.active,
        &:focus, 
        &:hover, 
        &:not(:disabled):not(.disabled).active, 
        &:not(:disabled):not(.disabled):active,
        &.dropdown-toggle {
            box-shadow: -0.9px 0.5px 3px 0 rgba(0, 0, 0, 0.5);
            color: @accent-color-blue;
            background-color: @accent-color-white;
        }

        &:disabled {
            color: @accent-color-dark;
            background-color: @color-gray-8;
            cursor: default;
            opacity: 1;
        }

        i {
            margin-right: 4px;
        }
    }
}