// ®

@import 'src/public/styles/vars';

// @font-face {
//   font-family: 'Futura PT';
//   src: url('https://bwcontentserver.azureedge.net/webFonts/Futura%20PT%20Book/Futura PT Book.ttf');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/public/fonts/Futura%20PT%20Book/Futura PT Book.ttf');
  src:
    url('../../assets/public/fonts/Futura%20PT%20Book/Futura PT Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FreightSansProSemibold';
  src: url('../../assets/public/fonts/FreightSansPro/FreightSansProSemibold-Regular.eot');
  src:
    local('FreightSans Pro Semibold'),
    local('FreightSansProSemibold-Regular'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProSemibold-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProSemibold-Regular.woff2') format('woff2'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProSemibold-Regular.woff') format('woff'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProSemibold-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'FreightSansProBook';
  font-variant-numeric: lining-nums;
  src: url('../../assets/public/fonts/FreightSansPro/FreightSansProBook-Regular.eot');
  src:
    local('FreightSans Pro Book'),
    local('FreightSansProBook-Regular'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProBook-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProBook-Regular.woff2') format('woff2'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProBook-Regular.woff') format('woff'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProBook-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FreightSansProBlack';
  src: url('../../assets/public/fonts/FreightSansPro/FreightSansProBlack-Regular.eot');
  src:
    local('FreightSans Pro Black'),
    local('FreightSansProBlack-Regular'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProBlack-Regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProBlack-Regular.woff2') format('woff2'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProBlack-Regular.woff') format('woff'),
    url('../../assets/public/fonts/FreightSansPro/FreightSansProBlack-Regular.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'FreightSansProMedium-Regular';
  src:
    local('Freight Sans Pro Medium Regular'),
    local('Freight-Sans-Pro-Medium-Regular'),
    url('../../assets/public/fonts/FreightSansProMedium-Regular/FreightSansProMedium-Regular.woff2') format('woff2'),
    url('../../assets/public/fonts/FreightSansProMedium-Regular/FreightSansProMedium-Regular.woff') format('woff'),
    url('../../assets/public/fonts/FreightSansProMedium-Regular/FreightSansProMedium-Regular.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'FreightSansProSemibold-Italic';
  src:
    local('FreightSansProSemibold-Italic'),
    local('FreightSansProSemibold-Italic'),
    url('../../assets/public/fonts/FreightSansProSemibold-Italic/FreightSansProSemibold-Italic.woff2') format('woff2'),
    url('../../assets/public/fonts/FreightSansProSemibold-Italic/FreightSansProSemibold-Italic.woff') format('woff'),
    url('../../assets/public/fonts/FreightSansProSemibold-Italic/FreightSansProSemibold-Italic.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

body{
	font-size: 18px;
	font-family: 'FreightSansProBook', sans-serif;
  line-height: 1.25;
  @media( min-width: 992px){
    body{
      font-size: 18px;
    }
  }
}

h1,h2,h3,h4,h5,h6{
  font-family: 'FreightSansProBlack', sans-serif;
}

.font-semi-bold{
  font-family: 'FreightSansProSemibold', sans-serif;
}

a{
  &:hover{
    color: theme( 'colors.orange');
  }
}
p{
  line-height: 1.35;
}

i{
  font-family: 'FreightSansProSemibold-Italic', sans-serif;
}

b{
  font-weight: 700;
}

.italic{
  font-style: italic;
}

body{
}
