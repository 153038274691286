@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/reset';
@import './styles/vars';
@import './styles/fonts';
@import './styles/container';
@import './styles/triangle';
@import './styles/list';
@import './styles/row-col';
@import './styles/section';

@import './styles/form';
@import './styles/custom-checkbox';

@import './styles/mixins';
@import './styles/bg';
@import './styles/calendar';

@import './styles/accordion';
@import './styles/links';

// external components
// @import './styles/ng2-date-picker.scss';

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'ngx-multiple-dates/prebuilt-themes/indigo-pink.css';

html,body{
  height: 100%;
}
.public-root{
  height: 100%;
  display: flex;
  justify-content: center;
}

.public-module{
  max-width: 100%;
}
