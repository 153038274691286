.wrapper-mat-calendar {
  width: 350px;
}


.mat-chip-list-wrapper{
  border: 1px solid #898989;
  border-radius: 4px;
  min-height: 42px !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}

.custom-mat-calendar{
  box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.5);
  .mat-icon-button{
    color: #FFD20F;
  }

  .mat-calendar-table-header{
    th{
      font-weight: 700;
      color: #000000;
    }
  }

  .mat-calendar-controls{
    margin: 0 !important;
    .mat-calendar-spacer{
      display: none;
    }
    .mat-calendar-previous-button{
      order: 0;
    }
    .mat-calendar-period-button{
      order: 1;
      flex-grow: 1;
    }
    .mat-calendar-next-button{
      order: 2;
    }
  }

  .mat-calendar-body{
    .mat-calendar-body-cell{
      .mat-calendar-body-cell-content{
        border-radius: 5px;
      }
      &.selected,
      &:hover,
      &.selected:hover{
        .mat-calendar-body-cell-content {
          background-color: #FFD20F !important;
        }
      }
      &:hover{
        .mat-calendar-body-cell-content {
          opacity: 0.5;
        }
      }
      &.mat-calendar-body-active{
        .mat-calendar-body-cell-content {
        }
      }
    }
  }

  .mat-calendar-body-cell.selected .mat-calendar-body-cell-content, .mat-calendar-body-cell.selected .mat-calendar-body-cell-content:hover, .mat-calendar-body-cell.selected .mat-calendar-body-cell-content:not(.disabled), .mat-calendar-body-cell.selected .mat-calendar-body-cell-content:not(.disabled):hover, .mat-calendar-body-cell.selected:hover .mat-calendar-body-cell-content, .mat-calendar-body-cell.selected:hover .mat-calendar-body-cell-content:hover, .mat-calendar-body-cell.selected:hover .mat-calendar-body-cell-content:not(.disabled), .mat-calendar-body-cell.selected:hover .mat-calendar-body-cell-content:not(.disabled):hover, .mat-calendar-body-cell.selected:not(.mat-calendar-body-disabled) .mat-calendar-body-cell-content, .mat-calendar-body-cell.selected:not(.mat-calendar-body-disabled) .mat-calendar-body-cell-content:hover, .mat-calendar-body-cell.selected:not(.mat-calendar-body-disabled) .mat-calendar-body-cell-content:not(.disabled), .mat-calendar-body-cell.selected:not(.mat-calendar-body-disabled) .mat-calendar-body-cell-content:not(.disabled):hover, .mat-calendar-body-cell.selected:not(.mat-calendar-body-disabled):hover .mat-calendar-body-cell-content, .mat-calendar-body-cell.selected:not(.mat-calendar-body-disabled):hover .mat-calendar-body-cell-content:hover, .mat-calendar-body-cell.selected:not(.mat-calendar-body-disabled):hover .mat-calendar-body-cell-content:not(.disabled), .mat-calendar-body-cell.selected:not(.mat-calendar-body-disabled):hover .mat-calendar-body-cell-content:not(.disabled):hover {
    background-color: #FFD20F !important;
  }

}

