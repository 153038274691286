@media (min-width: 576px) {
  .nav-user-menu .user-image {
    float: none;
    line-height: 10px;
    margin-top: -8px;
  }
}

.nav-user-menu .user-image {
  border-radius: 50%;
  float: left;
  height: 3rem;
  margin-top: -4px;
  width: 3rem;
}

.form-group.required .col-form-label:after {
  color: #d00;
  content: "*";
  position: absolute;
  margin-left: 3px;
}

/* Sweet alert */
.swal2-title {
  display: inline-block !important;
}

body.swal2-height-auto {
  height: inherit !important;
}

.swal2-popup.swal2-toast {
  padding: 10px 12px !important;
  display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
  text-align: left !important;
  margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
  margin-bottom: 4px !important;
}

div.wrapper,
div.content-wrapper,
div.sidebar {
  min-height: 100vh;
}


.swal2-container {
  .swal2-icon {
    transform: scale(0.8);
    margin: 0 0 10px;
    animation: none !important;
  }
  
  .swal2-title {
    font-size: 1.2em;
  }
  
  .swal2-content {
    font-size: 1em;
  }
  
  .swal2-styled.swal2-confirm {
    color: #000 !important;
    border-radius: 8px;
    background-image: linear-gradient(to bottom, #ffeb66 0, #fd0 100%) !important;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
    font-size: 14px !important;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 6px 20px !important;
    border: 1px solid #fd0 !important;
    text-transform: uppercase;
  
    &:hover,
    &.active {
        color: #000 !important;
        border-color: #fd0 !important;
        background-image: -webkit-linear-gradient(top, #fff199 0, #fd0 100%) !important;
        background-image: linear-gradient(to bottom, #fff199 0, #fd0 100%) !important;
    }
  }

  .swal2-popup.swal2-toast .swal2-content {
    font-size: 0.9em;
  }
}
