.btn-primary {
    color: #000;
    border-radius: 8px;
    background-image: linear-gradient(to bottom, #ffeb66 0, #fd0 100%);
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 15%);
    font-size: 14px !important;
    font-weight: 500;
    letter-spacing: 0.5px;
    padding: 6px 20px !Important;
    border: 1px solid #fd0;
    text-transform: uppercase;

    &:hover,
    &.active {
        color: #000;
        border-color: #fd0;
        background-image: -webkit-linear-gradient(top, #fff199 0, #fd0 100%);
        background-image: linear-gradient(to bottom, #fff199 0, #fd0 100%);
    }
}

.btn-xs {
    padding: 5px 8px !important;
    border-radius: 8px;
}

.btn-warning {
    color: #000;
    background-color: @accent-color-red;
}

.page-item.active .page-link {
    z-index: 3;
    color: black;
    background-color: @accent-color-yellow;
    border-color: @accent-color-yellow;
}

body {
    color: @color-black;
    font-family: Poppins, Helvetica,sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 300;
}

.form-control {
    font-size: inherit;
}

.btn-success {
    color: #fff;
    background-color: @accent-color-blue;
    border-color: @accent-color-blue;
    box-shadow: none;

    &:hover {
        opacity: 0.9;
        background-color: @accent-color-blue;
        border-color: @accent-color-blue;
    }
}

.text-info {
    color: @accent-color-blue !important;
}

.dropdown-menu a {
    color: @accent-color-blue !important;

    &:hover {
        opacity: 0.9;
        color: @accent-color-blue !important;
    }
}

.form-group .ui-autocomplete .ui-inputtext.form-control {
    &:focus,
    &:focus:hover {
        border-color: @accent-color-yellow;
        box-shadow: none;
    }
}

.form-group .ui-autocomplete .ui-inputtext.form-control {
  border-color: #ced4da;  
  &:hover {
    border-color: #ced4da;
  }
}

.width-percent-100 {
    width: 100%;
}

.bs-datepicker-body table td.week span 
{
  color: @accent-color-orange !important; 
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-head,
.bs-datepicker-head, .bs-datepicker button:active,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after,
.bs-datepicker-body table td.active-week span:hover 
{
  background-color: @accent-color-yellow !important; 
  color: @color-black !important; 
}

.bs-datepicker-head button {
    color: @color-black !important; 
}

.datapicker-container {
    position: relative;

    i {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
    }
}

.timepicker-backdrop-overlay,
.timepicker-overlay {
    z-index: 1052 !important;
}

.timepicker {

    .timepicker__header {
      background-color: @accent-color-yellow !important;
    }
  
    .clock-face__clock-hand {
      background-color: @accent-color-yellow !important;
  
      &::after {
        background-color: @accent-color-yellow !important;
      }
  
      &::before {
        border-color: @accent-color-yellow !important;
      }
    }
  
    .clock-face__number {
      > span.active {
        background-color: @accent-color-yellow !important;
      }
    }
  
    button:not(.timepicker-dial__item) {
      color: @color-black !important;
    }
  }
.period-selector__button--active {
    color: @accent-color-blue !important;
}

.content {
  padding: 0 16px;

  @media (max-width: @screen-xs-to) {
    padding: 0;
  }
}

.clickable-item {
  cursor: pointer;

  &:focus {
    outline-style: none;
  }
}

.pac-container {
  z-index: 2021;
}

.agm-map {
  height: 300px;
}

.modal-title {
  line-height: 1.3;
  font-size: 18px;
}

.content-header {
  padding: 32px 16px 10px 16px;

  h1 {
    font-size: 1.4rem;
  }

  @media (max-width: @screen-xs-to) {
      padding: 10px 0;
  }
}

.user-notification-item-clickable {
  cursor: pointer;
}


@media (max-width: @screen-xs-to) {
  .dropdown-menu.dropdown-menu-right.dropdown-menu-anim-up.dropdown-menu-sm {
    left: -269px !important;
  }

  .card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .primeng-datatable-container .ui-table table tbody {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  body .ui-table .ui-table-tbody > tr {
    display: flex;
    flex-direction: column;
  }

  body .ui-table.ui-table-responsive .ui-table-tbody > tr td {
    display: flex;
    flex-direction: column;
    padding: 5px !important;
  }


}

.custom-dropdown-menu {
  left: -88px;
  top: 4px;
}

.swal2-icon.swal2-warning::before {
  content: "" !important;
}

.rate-container {
  &.rate-container-sm {
    span.ng-star-inserted {
      font-size: 16px;
    }
  }

  span.ng-star-inserted {
    font-size: 30px;
    color: @accent-color-yellow;
    line-height: 1;
  }

  ngb-rating:focus {
    outline: none;
  }
}

.ui-tree {
  width: 100%;
}

textarea {
  padding: .375rem .75rem;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  
  &:focus {
    border-color: #fd0;
    box-shadow: none;
  }
}


.header-nav-item-username {
  display: flex;
  padding: 0.25rem 1rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
  font-weight: 600;
  width: max-content;

  i {
    font-size: 10px;
    margin-left: 3px;
  }
}

a.nav-link.header-nav-item-profile-image {
  height: auto;
}


.dropdown-menu-right-lg {
  max-width: 300px;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 500;
}

body a {
  color: inherit;
  text-decoration: none;
}

.nav-tabs .nav-item.show .nav-link, 
.nav-tabs .nav-link.active {
  font-weight: 600;
}

.header-container {
  background-color: #fff;

  &__line-container {
    
    &__line {
      background-color: @accent-color-yellow;
      transform: translate(0, 50%);
      width: 70%;
      position: absolute;
      height: 40px;

      @media (max-width: @screen-m-from) {
        background-color: #ffdd00;
        transform: translate(0, 50%);
        width: 51%;
        position: absolute;
        height: 30px;
        top: 9px;
      }

      @media(max-width: @screen-l-from) {
        width: 77%;
      }

      @media(max-width: @screen-sm-from) {
        width: 60%;
      }
    }

    &__image {
      height: 50px;
      right: 31%;
      position: absolute;
      top: 14px;

      @media(max-width: @screen-l-from) {
        height: 50px;
        right: 24%;
        position: absolute;
      }

      @media(max-width: @screen-m-from) {
        height: 32px;
        top: 24px;
      }

      @media(max-width: @screen-sm-from) {
        height: 29px;
        top: 25px;
        right: auto;
        left: 81px;
      }
    }
  }
}

.header-background {
  background-color: #f1f3f2;
}


body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:before {
  transform: translateX(2.25em) !important;
}

body .ui-inputswitch {
  width: 4em !important;
}

.sub-description {
  font-size: 11px;
  font-weight: 500;
}

.order-list__services {
  font-style: 13px;
}

.ngx-timepicker-control__arrows {
  line-height: initial;
}

.timepicker-dial__item {
  color: rgb(0 0 0 / 50%) !important;
}

.timepicker-dial__item_active {
  color: #000 !important;
}

.clock-face__number > span.active {
  color: #000 !important;
}

.btn.notificationIcon {
  padding: .5rem 0.52rem;
}

@media (max-width: @screen-xs-to) {
  .order-page-header-actions {
    margin-top: 30px;
  }
}