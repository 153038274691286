.bg-gradient-white {
  position: relative;
  z-index: 1;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 120%;
    height: 100%;
    background: linear-gradient( 90deg, rgba(255,255,255,1) 0%, rgba(255,255,255, 0.8 ) 85%, rgba(255,255,255,0) 100%);
    z-index: -1;
  }
}


.text-color-red{
  color: red;
}

.underlined{
  text-decoration: underline;
  text-decoration-color: #FFE000;
  text-underline-offset: 4px;
}
