* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
	margin: 0;
	line-height: 1.6;
}

a {
	display: inline-block;
  color: inherit;
	text-decoration: none;
	cursor: pointer;
}

button{
	padding: 0;
	margin: 0;
	outline: none;
	background-color: transparent;
	font-size: inherit;
	font-family: inherit;
  border: none;
	cursor: pointer;
}

fieldset{
	border:none;
	padding: 0;
}

input, textarea{
	outline: none;
	background-color: transparent;
	border: none;
	color: inherit;
}
textarea{
	resize: none;
}

h1,h2,h3,h4,h5,h6{
	margin: 0;
}

ul{
	padding: 0;
	margin: 0;
	list-style-type: none;
}

img{
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	object-fit: contain;
  max-width: 100%;
  height: auto;
}
