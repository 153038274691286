.container{
  width: 100%;
  margin: 0 auto;
  position: relative;
	padding: 0 20px;

  // TABLET
  @media ( min-width: 836px ){
    width: 740px;
  }

  // DESKTOP
  @media ( min-width: 1200px ){
    // width: 1260px;
    width: 1160px; //1120 + 20(padding) + 20 (padding)
  }
}
