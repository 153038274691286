@import './colors.less';
@import './media.less';
@import './components/sidebar/sidebar.less';
@import './components/sidebar/header-desktop-logo.less';
@import './components/core.less';
@import './labels.less';
@import './buttons.less';

body .app-container {
    font-family: 'FreightSansProBook', sans-serif;
    background: url('./../../assets/img/login/login-hero-6.jpg') no-repeat;
}

body .swal2-container,
body .app-container
{
  font-size: 26px;
  font-family: 'FreightSansProBook', sans-serif;

  small{
    font-size: 20px;
  }

  .content-header {
    color:#fff;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'FreightSansProBook', sans-serif;
    letter-spacing: 0px;
  }
}

