@media(max-width: @screen-l-from) {
    body {
        .header-menu-items {
            display: none;
        }
    }
}

.main-header {
    .header-menu-items {
        display: flex;
        margin-left: 97px;
        margin-top: 15px;

        .nav-item{
            transition: all 0.2s ease-in;
            margin-right: 6px;
            // font-family: @fontSourceSansPro;

            .nav-link {
                font-weight: 500;
                padding: 0 25px;
                transition: 0.3s color;
                white-space: nowrap;
                color: #898989;
                font-size: 20px;
            }
            
            &:hover {
                .nav-link {
                    color: #FFE000;
                }
            }
        }
    }
}

.main-header {
    height: 97px;
}

.header-desctop-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;

    a {
        padding: 0px;
    }

    .brand-image {
        max-height: 97px;
    }
    
    .brand-link .brand-image {
        margin: 0px;
    }

    .brand-text {
        display: none;
    }
}

.header-background {
    background-color: #f1f3f2;
    position: fixed;
    height: 300px;
    z-index: -1;
    top: 0;
    width: 100%;
}

.card {
    border-radius: 4px;
    padding: 20px;
}
