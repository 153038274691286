ul.list{
  list-style-type: none;
  padding-left: 30px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 20px;
  li{
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: -30px;
      top: 10px;
      width: 9px;
      height: 9px;
      background-color: #fff;
    }
  }
}
